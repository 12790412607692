.Section {
  flex: 1;
}

@import "./Mixin.scss";

label + .MuiInputBase-root.MuiInput-root {
  margin-top: 0;
}

//Alert

//Table
.MuiTableContainer-root.MuiPaper-root {
  @include borderRadius5;
  border: 1px solid var(--tableBorder);
  box-shadow: none;

  .MuiTable-root {
    @media screen and (max-width: 599px) {
      min-width: 599px;
    }

    .MuiTableHead-root {
      .MuiTableCell-head {
        background: var(--darkSlate);
        @include body;
        font-weight: 700;

        &.MuiTableCell-sizeSmall {
          padding: 6px 12px;
        }
      }
    }

    .MuiTableRow-root:nth-of-type(even) {
      background: var(--tableRowEven);
    }

    .MuiTableRow-root:nth-of-type(odd) {
      background: var(--tableRowOdd);
    }
  }

  .MuiTableCell-root {
    @include bodyXs;
    padding: 6px 12px;

    svg {
      filter: brightness(0) saturate(100%) invert(32%) sepia(91%)
        saturate(1220%) hue-rotate(157deg) brightness(87%) contrast(100%);
      width: 20px;
      height: 20px;
      cursor: pointer;
    }

    &.MuiTableCell-head {
      svg {
        filter: brightness(0) saturate(100%) invert(100%) sepia(0%)
          saturate(7500%) hue-rotate(69deg) brightness(97%) contrast(113%);
        width: 10px;
        height: 15px;
        cursor: pointer;
      }
    }
  }
}

//Label
.MuiFormLabel-root {
  &.MuiInputLabel-root {
    display: block;
    @include bodyXs;
    color: var(--unumBlueDark);
    cursor: pointer;
    font-weight: 700;

    .MuiInputLabel-asterisk:after {
      content: "whatever it is you want to add";
    }
  }
}

//Nativeselect
.MuiNativeSelect-root {
  width: 100%;

  .MuiNativeSelect-select {
    height: 30px;
    @include borderRadius5;
    border: 1px solid var(--unumWarnNeutralLight);
    margin-top: 1px;
    width: 100%;
    padding: 5px 10px;
    box-sizing: border-box;
    @include bodyXs;

    &:focus {
      @include borderRadius5;
      border-color: var(--unumBlueMid);
      outline: 0;
      -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%),
        0 0 8px rgb(102 175 233 / 60%);
      box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%),
        0 0 8px rgb(102 175 233 / 60%);
      background: var(--unumWhite);
    }

    &.Error,
    &.Mui-error {
      border: 1px solid var(--unumRed);
      box-shadow: inset 0px 0px 4px var(--unumRed);
    }

    &.Success {
      border: 1px solid var(--unumLawnDark);
      box-shadow: inset 0px 0px 4px var(--unumRed);
      background: var(-inputBgSuccess);
    }
  }

  svg.fa-angle-down {
    position: absolute;
    right: 10px;
    top: 8px;
    width: 10px;
  }

  &:before,
  &:after {
    display: none;
  }
}

//Checkbox
.MuiFormControlLabel-root {
  align-items: flex-start;
  margin: 0;

  .MuiTypography-root {
    display: block;
    @include body;
    color: rgba(0, 0, 0, 0.6);
    cursor: pointer;
    font-weight: 700;
  }

  .MuiCheckbox-root {
    margin-top: 1px;
    padding: 0;
    color: rgba(0, 0, 0, 0.6);
    border-width: 1px;
  }
}

//Label
.MuiFormControl-root {
  .MuiFormLabel-root {
    font-weight: 600;
  }
}

//Accordion
.MuiAccordion-root {
  width: 100%;

  &.Mui-expanded.MuiPaper-root {
    margin: 12px 0 0;
    background: var(--unumCoolNeutralLight);
    @include borderRadius5;

    .MuiCollapse-entered {
      border-top: 1px solid var(--unumCoolNeutral);
    }
  }

  &.MuiAccordion-rounded {
    box-shadow: none;
    margin-top: 12px;

    &:before {
      display: none;
    }
  }

  .MuiAccordionSummary-root {
    background: var(--unumCoolNeutral);
    @include borderRadius5;

    .MuiAccordionSummary-content {
      color: var(--unumBlueDark);
      @include bodyXs;
      font-weight: 700;

      &.Mui-expanded {
        margin: 15px 0;
      }
    }

    &.Mui-expanded {
      min-height: 48px;
      background: var(--unumCoolNeutralLight);
    }

    .MuiAccordionSummary-expandIconWrapper {
      color: var(--unumBlueDark);
      font-size: 25px;
    }
  }
}

.customAccordion1 {
  .MuiAccordion-root {
    &.Mui-expanded.MuiPaper-root {
      margin: 0;
      background: var(--unumCoolNeutralLight);
      @include borderRadius5;

      .MuiCollapse-entered {
        border-bottom: 1px solid var(--unumBlueLight);
      }
    }

    &.MuiAccordion-rounded {
      margin-top: 0;
    }

    .MuiAccordionSummary-root {
      background: var(--unumCoolNeutralLight);
      border-radius: initial;
      border-bottom: 1px solid var(--unumBlueLight);

      .MuiAccordionSummary-content {
        color: var(--unumBlueDark);
        @include bodyXs;
        font-weight: 700;
        align-items: baseline;
        flex-wrap: wrap;
        gap: 40px;

        &.Mui-expanded {
          margin: 14px 0;
        }

        span {
          @include h6Body;
          color: var(--unumWarnNeutral);
        }

        span:first-of-type {
          @include h5Body;
          color: var(--unumBlueDark);
        }
      }

      &.Mui-expanded {
        min-height: 48px;
        background: var(--unumCoolNeutralLight);
        border-radius: initial;
      }

      .MuiAccordionSummary-expandIconWrapper {
        color: var(--unumBlueDark);
        font-size: 25px;
      }
    }
  }
}

//Date
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  transform: translate3d(120px, 0px, 0px) !important;

  &:before,
  &:after {
    left: 0 !important;
  }
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  border-color: var(--unumBlueDark) !important;

  &:hover {
    border-color: var(--unumBlueMid) !important;
  }
}

.react-datepicker__navigation-icon {
  &:before {
    border-color: var(--unumBlueDark) !important;
  }

  &:hover {
    border-color: var(--unumBlueMid) !important;

    &:before {
      border-color: var(--unumBlueMid) !important;
    }
  }
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background: var(--unumBlueDark) !important;

  &:hover {
    background: var(--unumBlueMid) !important;
  }
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
  &:hover {
    background: var(--unumBlueLight) !important;
    color: var(--unumWhite) !important;
  }
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  color: var(--unumBlueDark) !important;
}

//Stepper
.MuiStepper-root {
  .MuiStep-root {
    .MuiStepConnector-root {
      top: 34px;
    }

    .MuiStepConnector-line {
      background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="17" height="4"><image width="13" height="4" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAECAYAAABV5LW0AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABnSURBVHgBnY7BDYAgDEULkTsjsAGs4gaO4Eiu4AYOQFLdgBG8A60lcvRgeEnT9ufn5ytEtKWURWvtmTkAgIUXJ5P63fZNRHut9VAxRhQhwH/OKec8G2NWeXxPdx/G1MzS5JJWG4zwAC6IJPGAQnLzAAAAAElFTkSuQmCC" /></svg>');
      background-position: left center;
      background-repeat: repeat-x;
      height: 4px;
      border: none;

      @media screen and (max-width: 700px) {
        background: none;
        height: 1px;
        border: 1px solid var(--unumWarnNeutralLight);
      }
    }

    .MuiStepLabel-iconContainer {
      background: url(../../icons/stepperMile.svg) no-repeat;
      width: 20px;
      height: 38px;
      position: relative;
    }

    .MuiStepLabel-label {
      color: var(--unumSlate);
      @include bodyXs;

      @media screen and (max-width: 700px) {
        display: none;
      }

      &.Mui-completed {
        @include bodyXs;
      }

      &.Mui-active {
        color: var(--unumBlueDark);
        @include body;
        font-weight: 700;

        @media screen and (max-width: 700px) {
          display: block;
          font-size: 0.7rem;
          margin-top: 5px;
        }
      }
    }

    .ellipse {
      position: absolute;
      top: -3px;
      width: 100%;
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        font-size: 14px;
        color: var(--unumBlueDark);
      }
    }

    .Mui-active {
      .ellipse {
        svg {
          font-size: 12px;
        }
      }
    }

    .Mui-completed,
    .Mui-active {
      .MuiStepConnector-line {
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="17" height="4"><image width="13" height="4" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAECAYAAABV5LW0AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABkSURBVHgBjY1RDYAwDESvDiZhDrCwZPyDlFrAAZImAQeAgxog47bw0eyLS65tmnc5waikgTO4j6Hs5hHBrIl7aTgdh4DXgUo/2ARZT0Yj/qrikn5kXXuTYPr6o4OsgbxuurCpvDtJFiewDXgrAAAAAElFTkSuQmCC" /></svg>');
        background-position: left center;
        background-repeat: repeat-x;

        @media screen and (max-width: 700px) {
          background: none;
          border: 1px solid var(--unumBlueDark);
          height: 1px;
        }
      }
    }

    .Mui-disabled {
      .MuiStepLabel-iconContainer {
        background: url(../../icons/stepperMileDisabled.svg) no-repeat;
        width: 20px;
        height: 38px;
        position: relative;
      }
    }
  }
}

//Dialog
.MuiDialog-root {
  .MuiDialog-container {
    .MuiPaper-root {
      border: 1px solid var(--unumCoolNeutralDark);
      min-width: 400px;
      max-width: 60%;
      width: 604px;
      padding: 50px;

      .MuiTypography-h6 {
        @include h5;
        color: var(--unumWarnNeutralDark);
        text-align: center;
        padding-bottom: 35px;
        padding-top: 0;
      }

      .MuiDialogContent-root {
        padding-bottom: 25px;

        p.MuiTypography-root {
          @include body;
          color: var(--unumWarnNeutralDark);
          text-align: center;
        }
      }

      .MuiDialogActions-root {
        justify-content: center;
        gap: 70px;
      }

      .modalClose {
        width: 24px;
        height: 24px;
        top: 25px;
        right: 25px;
        position: absolute;
        cursor: pointer;
        color: var(--unumBlack);

        &:hover,
        &:focus {
          color: var(--unumBlueMid);
        }
      }
    }
  }
}

//Box1 grid
.box1 {
  .MuiGrid-root {
    .MuiPaper-root {
      box-shadow: none;
    }
  }

  .MuiTableContainer-root.MuiPaper-root {
    @include borderRadius5;
  }
}
