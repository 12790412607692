.HB_Button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  gap: 12px;
  border: none;
  cursor: pointer;
  background: #dfe7ec;
  border-radius: 100px;
  flex: none;
  order: 1;
  flex-grow: 0;
  font-family: "Ubuntu", sans-serif;
  font-weight: bold;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: var(--unumBlue);
  height: 38px;
}

.HB_Button:hover {
  background-color: #d5dce0;
}

.HB_Dropdown {
  background: white;
  height: 50vh;
  width: 50vh;
  position: absolute;
  border-radius: 10px;
  display: none;
}

.HB_Dropdown.Active {
  display: block;
}

.HB_Dropdown.Left {
  right: 0;
}
