@import '../Common/Mixin.scss';

//Search components
.customSearch {
    .customInputField {
        label {
            color: var(--unumWhite);
            @include body;
            font-weight: 600;
        }

        .errorTxt {
            color: var(--unumWhite);
            border-color: var(--unumWhite);
        }
    }

    .MuiButton-root {
        &.MuiButton-containedPrimary {
            background: var(--unumBlue);
            &:hover {
                background: var(--unumBlueMid);
            }
        }
        
        .MuiButton-startIcon {
            svg {
                font-size: 15px;
            }
        }
        &.MuiButton-sizeLarge {
            padding: 6px 25px;
        }
        &.MuiButton-containedInfo {
            font-size: 1rem;
            padding: 8px 25px;
        }
        &.btnSearch {
            width: 100%;
        }
    }

    .MuiFormControlLabel-root {
        .MuiFormControlLabel-label {            
            @include bodyXs;
            color: var(--unumWhite);
            cursor: pointer;
            font-weight: 700;
            margin-bottom: 12px;
        }

        .MuiCheckbox-root {
            padding: 0;
            &.MuiCheckbox-colorPrimary {
                @include borderRadius5;
            }
        }
    }

    .customCheckBox {
        color: var(--unumWhite);
        gap: 5px;
        margin-bottom: 0;
        .inner {
            display: flex;
            align-items: center;
            gap: 10px;
        }
    }
}

//Tab
.tabContainer {
    .MuiBox-root {
        padding: 0;
    }
}

//Radio
.customRadio {
    gap: 10px;
}

//Avatar
.MuiAvatar-root {
    &.MuiAvatar-circular {
        width: 58px;
        height: 58px;
        &.avatarGreen {
            background: var(--avatarGreen);
        }
        &.avatarOrange {
            background: var(--avatarOrange);
        }
        &.avatarBlue {
            background: var(--avatarBlue);
        }
        svg {
            font-size: 32px;
        }
    }
}

//Help
.MuiButtonBase-root {
    &.helpBtn.MuiButton-containedPrimary {
        background: var(--unumCoolNeutral);
        color: var(--unumBlueDark);
        box-shadow: none;
        &:hover {
            background: var(--unumCoolNeutralLight);
            box-shadow: none;
        }
    }
}

header.helpSlide {
    & + .helpOpened {
        transition: all 1s;
        right: 0;
    }
}

//Accordion
.accordionFirstM0 {
    .MuiAccordion-root.MuiAccordion-rounded:first-child {
        margin-top: 0;
    }
}