html {
  box-sizing: border-box;
  font-size: 16px;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

header,
nav,
section,
div,
span,
a,
table,
td,
th,
select,
input {
  box-sizing: border-box;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

ol,
ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

form {
  width: 100%;
}

fieldset {
  border: none;
  margin: 0;
  padding: 0;
}

/* Custom scroll */
@media screen and (min-width: 992px) {
  html {
    overflow: auto;
  }
  body {
    overflow-y: scroll;
    overflow-x: hidden;
  }
  ::-webkit-scrollbar {
    height: 10px; /* height of horizontal scrollbar ← You're missing this */
    width: 10px; /* width of vertical scrollbar */
    //background: var(--unumWarnNeutralLight);
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
  }
}
