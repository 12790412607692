.NI_Main {
  display: flex;
  height: 60px;
  color: white;
  border-radius: 0px;
  transition: border-radius 0.2s;
  font-size: 1.5rem;
  font-weight: bold;
}

.NI_Icon {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 60px;
  filter: brightness(0) invert(1);
}

.NI_Main.selected .NI_Icon {
  filter: none;
}

.NI_Extra {
  visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  clip-path: polygon(0% 0, 0% 0%, 0% 100%, 0% 100%);
  transition: all 1.5s;
  font-weight: bold;
  font-size: 1rem;
}

.NI_Main.expanded .NI_Extra {
  visibility: visible;
  clip-path: polygon(0% 0, 100% 0%, 100% 100%, 0% 100%);
}

.NI_Main.expanded {
  border-radius: 0px 100px 100px 0px;
  transition: border-radius 1.5s;
  width: 95%;
}

.NI_Main.selected {
  background-color: white;
  color: var(--unumBlueDark);
}

a.NI_Main {
  text-decoration: none;
  text-wrap: nowrap;
}

a.NI_Main:hover,
a.NI_Main:focus,
a.NI_Main.active {
  color: white;
}

a.NI_Main.selected:hover,
a.NI_Main.selected:focus,
a.NI_Main.selected.active {
  color: var(--unumBlueDark);
}
