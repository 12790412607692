/* Footer.module.css */

.F_Main {
    width: 100%;
    color: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 50px;
    font-family: "Open Sans", sans-serif;
  }
  
  .F_Main a,
  .F_Main h2 {
    color: inherit;
    font-size: 1rem;
    line-height: normal;
    font-family: "Open Sans", sans-serif;
    text-decoration: none;
  }
  
  .F_Main h2 {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 25px;
  }
  
  @media (min-width: 800px) {
    .F_LinkMain {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }
  
  .F_wrapper {

    min-width: 320px;
  }
  
  .F_Divider {
    margin-top: 50px;
    margin-bottom: 50px;
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: inherit; /* Inherits the color property from its parent */
    }

    .F_SContent{
      margin-bottom: 50px;
      display: flex;
      align-items: center;

      width: 100%;
      gap:32px;
    }

.F_SItem {
    all: unset;
    cursor: pointer;
    font-weight: 400;
    font-family: "Open Sans", sans-serif;    
    line-height: 32px;
    font-size: 16px;
}

    .F_SItem:focus-visible{
      outline: 1px solid black;
    }
  
    .F_SDivider{
      width: 1px;
      height: 36px;
      background: white;
    }

  .F_CopyRight {
    padding-bottom: 20px;
    color: inherit;
    font-weight: bold;
  }
  
  .F_LinkMain li {
    padding: 10px 0px;
    list-style: none;
  }
  
  .F_Logo {
    height: 50px;
  }
  
  .F_Icon {
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
  }
  
  .F_IconList {
    margin: 50px 0px;
    display: flex;
    gap: 1rem;
    list-style: none;
    height: auto;
  }
  