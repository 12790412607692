.NB_Main {
  display: flex;
  background-color: var(--unumBlueDark);
  flex-direction: column;
  width: 100%;
  gap: 1rem;
  height: 100%;
  transition: width 1.5s;
  border-right: 1px solid white;
}

.NB_Logo {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  border-bottom: 1px solid white;
}

.NB_Bottom {
  border-top: 1px solid white;
  margin-top: auto;
  height: 88px;
  display: flex;
  align-items: center;
}

/*nav items expand*/

.NB_Main.expanded {
  width: 450%;
}
