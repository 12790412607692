@import "./Mixin.scss";

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");
/* Body (Ubuntu) */
body {
  background: var(--unumWhiteMid);
  font-family: "Open Sans", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  /* identical to box height, or 100% */
  letter-spacing: -0.02em;
  color: var(--unumBlueDark);

  .bold {
    font-weight: 700;
  }
  .semiBold {
    font-weight: 600;
  }
  .medium {
    font-weight: 500;
  }
  .light {
    font-weight: 300;
  }
}

/* Header 1 (Ubuntu) */
h1,
.h1 {
  font-family: "Ubuntu", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 3.25rem;
  line-height: 60px;
  letter-spacing: -0.02em;
  color: var(--unumBlueDark);
  &.bold {
    font-weight: 700;
  }
  &.semiBold {
    font-weight: 600;
  }
  &.medium {
    font-weight: 500;
  }
  &.light {
    font-weight: 300;
  }
}

/* Header 2 (Ubuntu) */
h2,
.h2 {
  font-family: "Ubuntu", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 2.25rem;
  line-height: 44px;
  letter-spacing: -0.02em;
  color: var(--unumBlueDark);
  &.bold {
    font-weight: 700;
  }
  &.semiBold {
    font-weight: 600;
  }
  &.medium {
    font-weight: 500;
  }
  &.light {
    font-weight: 300;
  }
}

/* Header 3 (Ubuntu) */
h3,
.h3 {
  font-family: "Ubuntu", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.75rem;
  line-height: 36px;
  letter-spacing: -0.02em;
  color: var(--unumBlueDark);
  &.bold {
    font-weight: 700;
  }
  &.semiBold {
    font-weight: 600;
  }
  &.medium {
    font-weight: 500;
  }
  &.light {
    font-weight: 300;
  }
}

/* Header 4 (Ubuntu) */
h4,
.h4 {
  font-family: "Ubuntu", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 32px;
  letter-spacing: -0.02em;
  color: var(--unumBlueDark);
  &.bold {
    font-weight: 700;
  }
  &.semiBold {
    font-weight: 600;
  }
  &.medium {
    font-weight: 500;
  }
  &.light {
    font-weight: 300;
  }
}

/* Header 5 (Ubuntu) */
h5,
.h5 {
  font-family: "Ubuntu", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 28px;
  letter-spacing: -0.02em;
  color: var(--unumBlueDark);
  &.bold {
    font-weight: 700;
  }
  &.semiBold {
    font-weight: 600;
  }
  &.medium {
    font-weight: 500;
  }
  &.light {
    font-weight: 300;
  }
}

/* Header 6 (Open Sans) */
h6,
.h6 {
  font-family: "Open Sans", serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: var(--unumBlueDark);
  &.bold {
    font-weight: 700;
  }
  &.semiBold {
    font-weight: 600;
  }
  &.medium {
    font-weight: 500;
  }
  &.light {
    font-weight: 300;
  }
}

.headingWithBg {
  background: var(--unumBlueDark);
  width: 100%;
  padding: 11px 20px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    color: var(--unumWhite);
    display: flex;
    align-items: center;
    gap: 10px;

    img {
      height: 30px;
    }
  }
}

.body {
  font-family: "Open Sans", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 24px;
  /* identical to box height, or 100% */
  color: var(--unumBlueDark);
  .bold {
    font-weight: 700;
  }
  .semiBold {
    font-weight: 600;
  }
  .medium {
    font-weight: 500;
  }
  .light {
    font-weight: 300;
  }
}

.bodyXl {
  font-family: "Open Sans", serif;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 32px;
  letter-spacing: -0.02em;
  &.bold {
    font-weight: 700;
  }
  &.semiBold {
    font-weight: 600;
  }
  &.medium {
    font-weight: 500;
  }
  &.light {
    font-weight: 300;
  }
}

.bodyL {
  font-family: "Open Sans", serif;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 28px;
  letter-spacing: -0.02em;
  &.bold {
    font-weight: 700;
  }
  &.semiBold {
    font-weight: 600;
  }
  &.medium {
    font-weight: 500;
  }
  &.light {
    font-weight: 300;
  }
}

.bodyXs {
  font-family: "Open Sans", serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 20px;
  letter-spacing: -0.02em;
  &.bold {
    font-weight: 700;
  }
  &.semiBold {
    font-weight: 600;
  }
  &.medium {
    font-weight: 500;
  }
  &.light {
    font-weight: 300;
  }
}

.bodyXxs {
  font-family: "Open Sans", serif;
  font-weight: 400;
  font-size: 0.64rem;
  line-height: 16px;
  letter-spacing: -0.02em;
  &.bold {
    font-weight: 700;
  }
  &.semiBold {
    font-weight: 600;
  }
  &.medium {
    font-weight: 500;
  }
  &.light {
    font-weight: 300;
  }
}

.bodyXxxs {
  font-family: "Open Sans", serif;
  font-weight: 400;
  font-size: 0.512rem;
  line-height: 14px;
  letter-spacing: -0.02em;
  &.bold {
    font-weight: 700;
  }
  &.semiBold {
    font-weight: 600;
  }
  &.medium {
    font-weight: 500;
  }
  &.light {
    font-weight: 300;
  }
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px !important;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
}
.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
}

a {
  color: var(--unumPoolDark);
  cursor: pointer;
  text-decoration: underline;
  &:hover,
  &:focus,
  &.active {
    color: var(--unumBlueDark);
  }
  &.bold {
    font-weight: 600;
  }
  &.semiBold {
    font-weight: 500;
  }
}

.sr-only {
  height: 1px;
  left: -10000px;
  overflow: hidden;
  position: absolute;
  top: auto;
  width: 1px;
}

.layoutMinVh {
  min-height: 100vh;
  position: relative;
}

/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid var(--unumWarnNeutralLight);
  -webkit-text-fill-color: var(--unumWarnNeutral);
  -webkit-box-shadow: 0 0 0px 1000px #f3f5f5 inset;
  box-shadow: 0 0 0px 1000px #f3f5f5 inset;
  transition: background-color 5000s ease-in-out 0s;
}

.container,
.modalWindow {
  padding: 89px 50px 120px 50px;

  @media screen and (max-width: 991px) {
    padding: 110px 20px 120px 20px;
  }

  .box1 {
    padding: 25px 20px;
    border: 1px solid var(--unumCoolNeutralDark);
    border-top: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background: var(--unumWhite);
    overflow: hidden;

    &.shadow1 {
      border: none;
      box-shadow: var(--shadowstyle1);
      &:last-child {
        margin-bottom: 0;
      }
    }

    &.shadow2 {
      box-shadow: var(--shadowstyle1);
      &:last-child {
        margin-bottom: 0;
      }
    }

    &.noBorder {
      border: none;
      &.noPadLR {
        padding-left: 0;
        padding-right: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }

    &.allBorder {
      border: 1px solid var(--unumCoolNeutralDark);
      @include borderRadius5;
    }

    &.noPad {
      padding: 0;
    }
  }

  .reviewTxt {
    color: var(--unumBlack);
    @include bodyXs;
    font-weight: 700;
  }

  .errorTxt {
    color: var(--unumRed);
    @include bodyXxs;
    font-weight: 700;
    border-left: 3px solid var(--unumRed);
    padding: 0 5px;
    display: block;
    margin-top: 5px;

    &.default {
      border-left: none;
      padding-left: 0;
      font-weight: 400;
    }
  }

  .successTxt {
    color: var(--unumLawn);
    @include bodyXxs;
    font-weight: 700;
    border-left: 3px solid var(--unumRed);
    padding: 0 5px;
    display: block;
    margin-top: 5px;

    &.default {
      border-left: none;
      padding-left: 0;
      font-weight: 400;
    }
  }

  .statusAlert {
    color: var(--unumWhite);
    padding: 10px 20px;
    border-radius: 50px;
    cursor: pointer;
    @include bodyXs;
    font-weight: 700;
    text-align: center;
    &.maxWidth60 {
      max-width: 61px;
    }
    &.error {
      background: var(--unumRed);
    }
    &.success {
      background: var(--unumLawn);
    }
    &.pending {
      background: var(--unumGold);
      color: var(--unumWarnNeutral);
    }
  }

  .noteSection {
    border: 1px solid var(--unumWarnNeutralLight);
    background: var(--unumWhiteMid);
    @include body;
    color: var(--unumBlack);
    @include borderRadius5;
    text-align: center;
    padding: 15px 20px;
  }

  .autoList {
    @include borderRadius5;
    border: 1px solid var(--unumBlueLight);
    width: calc(100% - 50px);
    position: absolute;
    z-index: 1;
    background: var(--unumWhite);
    max-height: 186px;
    overflow: auto;
    padding: 5px 0;
    @media screen and (max-width: 599px) {
      width: calc(100% - 25px);
    }

    li {
      height: 30px;
      margin-top: 1px;
      width: 100%;
      padding: 5px 10px;
      @include bodyXs;
      color: var(--unumWarnNeutral);
      cursor: pointer;

      &:first-child {
        margin-top: 0;
      }

      &:hover {
        background: var(--autoListHover);
      }
    }
  }

  hr {
    border: 0;
    border-top: 1px solid var(--unumBlueDark);
    margin: 0 !important;

    &.light {
      border: 0;
      border-top: 1px solid var(--unumBlueMid);
    }
    &.grey {
      border: 0;
      border-top: 1px solid var(--unumCoolNeutral);
    }
    &.greyDashed {
      border: 0;
      border-top: 1px dashed var(--unumCoolNeutralDark);
      margin: 0 -20px !important;
    }
    &.mTB_10 {
      margin: 10px 0 !important;
    }
    &.mTB_20 {
      margin: 20px 0 !important;
    }
    &.mTB_30 {
      margin: 30px 0 !important;
    }
    &.mT_20 {
      margin: 20px 0 0 !important;
    }
    &.mB_20 {
      margin: 0 0 20px !important;
    }
  }

  .centerHead {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 200px;
    text-align: center;
    &.min100 {
      min-height: 100px;
    }
  }

  h5.noData {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 200px;
    text-align: center;
    &.min100 {
      min-height: 100px;
    }
  }

  .claimActionBtn {
    width: calc(100% + 50px);
    color: var(--unumBlueDark);
    background: transparent;
    border: 1px solid transparent;
    padding: 10px 20px;
    position: relative;
    left: -50px;
    padding-left: 70px;
    border-radius: 0;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    justify-content: flex-start;
    @include bodyXs;

    .MuiButton-startIcon svg {
      width: 20px;
    }

    &:hover,
    &.selected {
      color: var(--unumWhite);
      background: var(--unumBlueDark);
      border: 1px solid var(--unumBlueDark);
    }

    // &.selected {
    //     background: var(--unumWhite);
    //     border: 1px solid var(--unumBlueDark);
    //     border-left: 1px solid transparent;
    // }

    @media screen and (max-width: 1199px) {
      width: 100%;
      left: 0;
      padding-left: 20px;
      border-top-left-radius: 25px;
      border-bottom-left-radius: 25px;
      justify-content: center;
      background: var(--unumWhite);
      border: 1px solid var(--unumBlueDark);
    }

    @media screen and (max-width: 991px) {
      width: auto;
    }

    @media screen and (max-width: 599px) {
      width: calc(50% - 5px);
    }
  }

  .hide {
    display: none;
  }

  .show {
    display: block;
  }

  .addressWithContact {
    gap: 100px;
    h5 {
      @include bodyXs;
      font-weight: 700;
      color: var(--unumBlueDark);
    }
    p {
      @include bodyXs;
      color: var(--unumWarnNeutral);
    }

    @media screen and (max-width: 991px) {
      gap: 50px;
    }

    @media screen and (max-width: 599px) {
      flex-direction: column;
      gap: 20px;
    }
  }

  .bankDetailCard {
    border: 1px solid var(--unumBlueDark);
    @include borderRadius5;
    flex: 1;
    h6 {
      @include bodyXs;
      font-weight: 700;
      color: var(--unumBlueDark);
      text-transform: uppercase;
      border-bottom: 1px solid var(--unumBlueDark);
      padding: 5px 10px;
    }
    p {
      @include bodyXs;
      color: var(--unumWarnNeutral);
    }
  }

  .chequeDetailCard {
    background: var(--unumCoolNeutralLight);
    @include borderRadius5;
    flex: 1;
    padding: 10px;
    h6 {
      @include bodyXs;
      font-weight: 700;
      color: var(--unumBlueDark);
    }
    p {
      @include bodyXs;
      color: var(--unumWarnNeutral);
    }
  }

  .summaryCard {
    gap: 100px;
    p {
      color: var(--unumWarnNeutral);
    }

    @media screen and (max-width: 991px) {
      gap: 50px;
    }

    @media screen and (max-width: 599px) {
      flex-direction: column;
      gap: 20px;
    }
  }

  .oddOrEvenRow {
    padding: 25px 20px;

    &:nth-of-type(even) {
      background: var(--tableRowEven);
    }
  }

  .downloadBtn {
    position: relative;

    .downloadPdf {
      position: absolute;
      top: 11px;
      right: 205px;

      button {
        padding: 12px 0;
      }
    }
  }
}

.container {
  overflow: auto;
  height: calc(100vh - 178px);
  padding: 0 33px 0 50px;
  margin: 89px 0;

  @media screen and (max-width: 991px) {
    padding: 0 20px 0 20px;
    margin: 120px 0 80px 0;
    height: calc(100vh - 200px);
  }

  @media screen and (max-width: 600px) {
    margin: 120px 0 110px 0;
    height: calc(100vh - 230px);
  }
}

//PageBottomCtrl
.pageBottomCtrl {
  // padding: 12px 50px;
  // background: var(--unumBlueLight);
  // position: absolute;
  // bottom: 0;
  // left: 0;
  // right: 0;
  padding: 12px 50px;
  background: var(--unumBlueLight);
  position: fixed;
  bottom: 0;
  width: calc(100% - 70px);

  @media screen and (max-width: 991px) {
    width: calc(100% + 0px);
  }
}

//Checkbox
.customCheckBox {
  // display: inline-flex;
  position: relative;
  cursor: pointer;
  @include body;
  color: var(--unumWarnNeutral);
  font-weight: 600;
  flex-direction: column;
  gap: 6px;
  margin-bottom: 5px;
  -webkit-user-select: none;
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

  .labelBody {
    font-weight: 400;
    @include body;

    label {
      cursor: pointer;
    }
  }

  /* Hide the browser's default checkbox */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  span:first-of-type {
    display: block;
  }

  /* Create a custom checkbox */
  .checkmark {
    display: inline-flex;
    position: relative;
    height: 25px;
    width: 25px;
    background: var(--unumWhite);
    border: 1px solid var(--unumWarnNeutralLight);
    @include borderRadius5;
    /* Create the checkmark/indicator (hidden when not checked) */
    &:after {
      content: "";
      position: absolute;
      display: none;

      /* Style the checkmark/indicator */
      left: 8px;
      top: 4px;
      width: 7px;
      height: 12px;
      border: solid var(--unumWhite);
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
  /* On mouse-over, add a grey background color */
  &:hover input ~ .checkmark {
    box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.25);
    border: 1px solid var(--unumBlueMid);
  }
  /* On focus, add a grey background color */
  input:focus ~ .checkmark {
    box-shadow: inset 0px 0px 0px 1px #417daf;
    border: 1px solid var(--unumBlueMid);
  }

  /* When the checkbox is checked, add a blue background */
  input:checked ~ .checkmark {
    background-color: var(--unumBlueMid);
    border: 1px solid var(--unumWarnNeutralLight);

    /* Show the checkmark when checked */
    &:after {
      display: block;
    }
  }

  &.error {
    input ~ .checkmark {
      border: 1px solid var(--unumRed);
      box-shadow: inset 0px 0px 0px 1px var(--unumRed);
    }
    input:focus ~ .checkmark {
      -webkit-box-shadow: 0 0 8px rgb(102 175 233 / 60%);
      box-shadow: 0 0 8px rgb(102 175 233 / 60%);
    }
  }

  input[disabled] ~ .checkmark {
    background: var(--disabled1);
  }

  input:checked[disabled] ~ .checkmark {
    &:after {
      border: solid var(--unumCoolNeutralDark);
      border-width: 0 3px 3px 0;
    }
  }
}

//Auth
.customAuth {
  display: inline-flex;
  position: relative;
  cursor: pointer;
  @include body;
  color: var(--unumWarnNeutral);

  font-weight: 600;
  flex-direction: column;
  margin-bottom: 5px;
  -webkit-user-select: none;
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

//Modal
.MuiModal-root {
  .modalWindow {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 400px;
    max-width: 60%;
    width: 1024px;
    background: var(--unumWhite);
    box-shadow: var(--shadowstyle1);
    @include borderRadius5;
    padding: 25px;
    max-height: calc(100vh - 100px);
    overflow: auto;

    .modalDesc {
      margin-top: 20px;
      .width100 {
        width: 100%;
      }
    }

    .modalClose {
      width: 24px;
      height: 24px;
      top: 25px;
      right: 25px;
      position: absolute;
      cursor: pointer;
      color: var(--unumBlack);

      &:hover,
      &:focus {
        color: var(--unumBlueMid);
      }
    }
  }
}

/* Hamburger menu */
.hamburger,
#menu_checkbox {
  display: none;
}

.hamburger {
  position: absolute;
  top: 50%;
  left: 0;
  width: 40px;
  height: 40px;
  cursor: pointer;
  padding: 5px;

  span {
    position: relative;
    top: 0;
    height: 4px;
    background: var(--unumBlueDark);
    margin-bottom: 6px;
    transition: 0.3s ease transform, 0.3s ease top, 0.3s ease width,
      0.3s ease right;
    border-radius: 2px;
    display: block;

    &:first-child {
      transform-origin: 0;
    }
    &:last-child {
      margin-bottom: 0;
      transform-origin: 30px;
    }
    &:nth-child(2) {
      right: 0;
      width: 30px;
    }
  }
}

#menu_checkbox {
  &:checked + .hamburger span {
    background: var(--unumWhite);

    &:first-child {
      top: 3px;
      transform: rotateZ(45deg);
      left: 3px;
      width: 32px;
    }

    &:nth-last-child(-n + 2) {
      top: 5px;
      left: -5px;
      transform: rotateZ(45deg);
      width: 30px;
      display: none;
    }

    &:nth-child(2) {
      width: 32px;
      right: 2px;
      top: 4px;
      transform: rotateZ(-45deg);
    }
  }
}

.navMenu {
  width: 70px;

  .brandName {
    .unumLogoShort {
      opacity: 1;
      display: inline-block;
    }

    .unumLogo {
      opacity: 0;
      display: none;
    }
  }

  .navTopMenu {
    ul {
      b {
        opacity: 0;
      }
    }

    .envName {
      width: 100%;
      color: var(--unumWhite);
      z-index: 1;
      transition: all 1s;
      position: absolute;
      bottom: 210px;
      left: 0px;
      -webkit-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      transform: rotate(-90deg);
    }
  }

  &:hover {
    width: 227px;

    .brandName {
      width: 227px;

      .unumLogoShort {
        opacity: 0;
        display: none;
      }

      .unumLogo {
        opacity: 1;
        display: inline-block;
      }
    }

    // navbar.css
    .navTopMenu {
      .envName {
        bottom: 210px;
        left: 20px;
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
      }

      ul {
        width: 227px;

        b {
          opacity: 1;
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .hamburger {
    display: block;
    top: 20px;
    left: 16px;
    // background: var(--unumWhite);
    border-radius: 50%;
    z-index: 9;
  }
}
