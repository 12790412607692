@mixin tablet {
  @media only screen and (min-width: 768px) {
    @content;
  }
}
@mixin desktop {
  @media only screen and (min-width: 992px) {
    @content;
  }
}

@mixin h5Body {
  font-family: "Ubuntu", serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 30px;
  letter-spacing: -0.02em;
}
@mixin h6Body {
  font-family: "Ubuntu", serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 24px;
  letter-spacing: -0.02em;
}
@mixin body {
  font-family: "Open Sans", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 24px;
  letter-spacing: -0.02em;
}
@mixin bodyXs {
  font-family: "Open Sans", serif;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 19.2px;
  letter-spacing: -0.02em;
}
@mixin bodyXxs {
  font-family: "Open Sans", serif;
  font-weight: 400;
  font-size: 0.64rem;
  line-height: 15.36px;
  letter-spacing: -0.02em;
}
@mixin bodyXxxs {
  font-family: "Open Sans", serif;
  font-weight: 400;
  font-size: 0.512rem;
  line-height: 12.288px;
  letter-spacing: -0.02em;
}
@mixin h5 {
  font-family: "Open Sans", serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 30px;
  letter-spacing: -0.02em;
  color: var(--unumBlueDark);
}
@mixin borderRadius5 {
  border-radius: 5px;
}
@mixin borderRadius20 {
  border-radius: 20px;
}
