.Main {
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.LoginCard {
  background-color: white;
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  justify-content: center;
  align-items: center;
}

.LoginButton {
  all: unset;
  padding: 10px;
  background-color: var(--unumBlueDark);
  color: white;
  font-weight: bold;
  font-size: 1.8rem;
  border-radius: 100px;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
  border: 2px solid transparent;
}

.LoginButton:hover {
  background-color: var(--unumBlue);
  cursor: pointer;
  scale: 1.02;
}

.LoginButton:focus-visible {
  background-color: var(--unumBlue);
  border: 2px solid black;
  cursor: pointer;
  scale: 1.02;
}

.LoginSubTitle {
  margin-top: 20px;
  color: var(--unumCoolNeutralDark);
  font-size: 3rem;
}

.LoginLogo {
  padding-bottom: 10px;
  border-bottom: 1px solid var(--unumBlue);
  width: 100%;
}

.LoginAvatar {
  color: var(--unumWarnNeutralLight);
  font-size: 4rem;
  border: 1px var(--unumWarnNeutralLight) solid;
  height: 120px;
  width: 120px;
  padding: 25px;
  border-radius: 1000px;
}

.LoginIconWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.LoginWarningText {
  padding-top: 20px;
  width: 300px;
  text-align: center;
  color: var(--unumWarnNeutral);
}

.LoginBottom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.LoginLoader {
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 100px 100px 100px 100px;
  border: 3px solid var(--unumBlueLight);
  clip-path: polygon(50% 50%, 20% 0, 80% 0);
  animation: spinning 3s linear infinite;
}

@keyframes spinning {
  0% {
    rotate: 0deg;
    opacity: 1;
  }
  100% {
    rotate: 360deg;
    opacity: 1;
  }
}
