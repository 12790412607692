:root {
  --unumBlue: #015294;
  --unumBlueLight: #78a5d1;
  --unumBlueMid: #417daf;
  --unumBlueDark: #004470;
  --unumWarnNeutral: #525252;
  --unumWarnNeutralDark: #2e2e2e;
  --unumWarnNeutralLight: #cccccc;
  --unumCoolNeutral: #dfe7ec;
  --unumCoolNeutralDark: #8993a4;
  --unumCoolNeutralLight: #f6f8fa;
  --unumSky: #00a6d7;
  --unumSkyLight: #a1d3ea;
  --unumSlate: #5a7184;
  --unumSlateDark: #26495f;
  --unumSlateLight: #aec7d8;
  --unumOrange: #e99625;
  --unumOrangeLight: #f3b13b;
  --unumOrangeDark: #d67921;
  --unumGold: #fac832;
  --unumLawn: #8cc342;
  --unumLawnLight: #b6d991;
  --unumLawnDark1: #288653;
  --unumLawnDark: #0a6a36;
  --unumPool: #0199a6;
  --unumPoolLight: #47b4bc;
  --unumPoolDark: #017f90;
  --unumWhite: #ffffff;
  --unumWhiteMid: #f3f5f5;
  --unumBlack: #000000;
  --unumRed: #a64441;
  --unumRedHover: #742f2d;
  --unumLawn: #2C862A;
  --unumBlue100: #E6EEF4;
  --feedbackSuccess100: #ECF9E6;
  --darkSlate: linear-gradient(
      0deg,
      rgba(38, 73, 95, 0.2),
      rgba(38, 73, 95, 0.2)
    ),
    linear-gradient(0deg, rgba(38, 73, 95, 0.5), rgba(38, 73, 95, 0.5)),
    linear-gradient(0deg, rgba(38, 73, 95, 0.8), rgba(38, 73, 95, 0.8)), #26495f;
  --tableBorder: #26495f;
  --tableRowOdd: #ffffff;
  --tableRowEven: #f3f5f5;
  --boxBorder1: #26495f;
  --inputBorder: #26495f;
  --inputBgSuccess: rgba(182, 217, 145, 0.5);
  --shadowstyle1: 0px 4px 4px rgba(0, 0, 0, 0.25);
  --disabled1: rgba(217, 217, 217, 0.5);
  --autoListHover: rgba(120, 165, 209, 0.25);
  --accordBg: #dfe7ec;

  --avatarGreen: #93c13e;
  --avatarOrange: #ef8822;
  --avatarBlue: #035493;

  --successBg: #f7f9e6;
  --successBorder: #539340;
  --infoBorder: #00a6d7;
  --warningBg: #fdf0e6;
  --warningBorder: #ef5f19;
  --errorBorder: #b0000f;
}

//color
.unumOrange,
.unumOrange svg {
  color: var(--avatarOrange);
}

.unumPoolLight,
.unumPoolLight svg {
  color: var(--unumPoolLight);
}

.unumBlueMid,
.unumBlueMid svg {
  color: var(--unumBlueMid);
}

.unumLawn,
.unumLawn svg {
  color: var(--unumLawn);
}

.unumLawnDark,
.unumLawnDark svg {
  color: var(--unumLawnDark);
}

.unumBlueLight,
.unumBlueLight svg {
  color: var(--unumBlueLight);
}

.unumWarnNeutral,
.unumWarnNeutral svg {
  color: var(--unumWarnNeutral);
}

.unumSky,
.unumSky svg {
  color: var(--unumSky);
}

.unumRed,
.unumRed svg {
  color: var(--unumRed) !important;
}

//Margin
.marginRight10 {
  margin-right: 10px !important;
}

.marginRight15 {
  margin-right: 15px !important;
}

.marginBot10 {
  margin-bottom: 10px !important;
}

.marginBot20 {
  margin-bottom: 20px !important;
}

.marginTop10 {
  margin-top: 10px !important;
}

.marginTop20 {
  margin-top: 20px !important;
}

.marginTop25 {
  margin-top: 25px !important;
}

.marginTop30 {
  margin-top: 30px !important;
}

.marginBot30 {
  margin-bottom: 30px !important;
}

//Overflow
.overflowHidden {
  overflow: hidden;
}

//Position
.posRelative {
  position: relative;
}

.posAbsolute {
  position: absolute;
}

.posStatic {
  position: static;
}

//Z-index
.z-index-1 + div {
  z-index: 1;
}

//text align
.txtCenter {
  text-align: center;
}

.txtLeft {
  text-align: left;
}

.txtRight {
  text-align: right;
}

//Height
.height20 {
  height: 20px;
}

//Width
.width21 {
  width: 21px;
}

.width50 {
  width: 50%;
}

.width100 {
  width: 100%;
}

.filterNone {
  filter: none !important;
}

//Media
.onlyDesktop {
  display: none !important;

  @media screen and (min-width: 992px) {
    display: flex !important;
  }
}

.onlyMobileTablet {
  display: none !important;

  @media screen and (max-width: 991px) {
    display: flex !important;
  }
}

.onlyTablet {
  display: none !important;

  @media screen and (min-width: 768px) and (max-width: 991px) {
    display: flex !important;
  }
}

.onlyMobile {
  display: none !important;

  @media screen and (max-width: 767px) {
    display: flex !important;
  }
}
