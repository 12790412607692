.main {
  display: flex;
  flex-direction: row;

  height: 100vh;
}
.leftContainer {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--unumWhite);
}
.rightContainer {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--unumBlueDark);
}

.pageNotFound {
  display: flex;
  padding: 25px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 25px;
}
.headingLabel {
  color: var(--unumBlueDark);
  /* Ubuntu/Ubuntu (Medium)/Header 1 */
  font-family: "Ubuntu", serif;
  font-size: 48.83px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.977px;
}
.textLayout {
  display: flex;
  width: 454px;
  flex-direction: column;
  flex-shrink: 0;
}
.buttonStyle {
  display: flex;
  width: 239px;
  height: 55px;
  padding: 6px 10px;
  font-family: "Open Sans", serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  color: var(--unumWhite);
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 48px;
  background: var(--unumBlueDark);
  box-shadow: 0px 4px 4px 0px rgba(128, 128, 128, 0.8);
}

.HealthPlanDiv {
  display: flex;
  align-items: flex-end;
  width: 100%;
}

.StretchSingleItem {
  display: grid;
}

.EqualSizeButtons {
  max-width: 294px;
  max-height: 294px;
}

@media screen and (max-width: 992px) {
  .HealthPlanDiv {
    display: flex;
    align-items: flex-start;
    width: 100%;
    flex-direction: column;
  }
}

.featureButton{
  background-color: #015294 !important;
  color:white !important;
  padding:0.5rem 1rem !important;
  }
  
  .featureButton:hover,.submitButton:focus,.submitButton:focus-visible{
    cursor: pointer;
    background-color: #004470 !important;
  }