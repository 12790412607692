.NV_Button {
  background-color: transparent;
  border: none;
  color: white;
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  margin:0;
  padding:0;
  align-items: center;
}
