.LayoutHeader {
  background-color: white;
  position: fixed;
  z-index: 5;
  height: 60px;
  width: calc(100% - 60px);
  margin-left: 60px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
}

.LayoutContent {
  padding-top: 60px;
  padding-left: 60px;
  z-index: 1;
}

.LayoutBackground {
  position: fixed;
  z-index: -1;
  background-color: var(--unumCoolNeutral);
  min-height: 650px;
  width: 100%;
  clip-path: ellipse(100% 50% at 50% 5%);
}

.LayoutSide {
  background-color: var(--UnumBlue);
  z-index: 6;
  height: 100%;
  width: 60px;
  position: fixed;
}

.LayoutBreadcrumb {
  position: fixed;
  z-index: 2;
}
