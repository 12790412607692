.BannerContainer {
  display: flex;
  background: #ffffff;
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.25);
  width: 100%;
  bottom: 0;
  padding: 38px 0 38px 42px;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;

  @media (max-width: 768px) {
    padding: 10px 0 10px 10px;
  }
}

.TextWrapper {
  width: 65%;
}

.BannerTitle {
  font-size: 20px;
  margin-bottom: 10px;
}

.BannerText {
  color: #525252;
  line-height: 22px;
}

.ButtonWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

@mixin CookieButton {
  border-radius: 50px;
  border: none;
  width: 230px;
  height: 46px;
  font-weight: 700;
  cursor: pointer;
}

.ButtonAccept {
  @include CookieButton;
  background: var(--unumBlueDark);
  color: #ffffff;
}

.ButtonReject {
  @include CookieButton;
  background: #ffffff;
  color: var(--unumBlueDark);
  border: 1px solid var(--unumBlueDark);
}
