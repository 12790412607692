.TM_Main {
  display: flex;
  width: 100%;
  justify-content: center;
  height: 100%;
  padding: 10px;
}

.TM_Left {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.TM_Right {
  display: flex;
  align-items: center;
}

.TM_HelpWrapper {
  margin-left: auto;
}

.TM_Title {
  line-height: 30px;
  margin: 0px;
  margin-left: 25px;
  color: var(--UnumBlue);
}

.TM_Logo {
  margin-left: 25px;
}
